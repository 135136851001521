<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import Transaction from '../../../models/Transaction';
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import moment from "moment";

    export default {
        name: "transactions-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                items: [],
                headers: [
                    {text: '# ID', value: 'id'},
                    {value: 'reference_id', text: 'Reference ID'},
                    {value: 'ext_transaction_id', text: 'External transaction id'},
                    {value: 'user_id', text: 'Recipient User id'},
                    {value: 'sender_id', text: 'Sender User id'},
                    {value: 'from', text: 'Text "from"'},
                    {value: 'to', text: 'Text "to"'},
                    {value: 'amount', text: 'Amount'},
                    {value: 'type', text: 'Type',},
                    {value: 'status', text: 'Status'},
                    {value: 'deleted_at', text: 'Deleted Time'},
                    {value: 'created_at', text: 'Created Time'},
                    {value: 'updated_at', text: 'Updated Time'},
                ],
            }
        },
        methods: {
            handleClickRow(items) {
                this.$router.push({name: 'admin.transactions.show', params: {id: items.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.items = await Transaction.get()
            this.items.map((value) => {
                value.deleted_at = moment(value.deleted_at).format('lll')
                value.created_at = moment(value.created_at).format('lll')
                value.updated_at = moment(value.updated_at).format('lll')
            })
            this.loading = false
        },
    }
</script>

<style scoped>
    .container {
        max-width: inherit !important;
    }
</style>
